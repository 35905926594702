var Login = function() {
    return {
        init: function() {

            var videoDiv;
            var logoDiv;

            var slider_config = {
                'static': false,
                'shuffle': true,
                'fade': 1000,
                'duration': 8000,
                'limit': 8
            };
            if (slider_config.static == false) {

                // TODO: criar um serviço que periodicamente gere um json com as imagens abaixo e esse script consuma
                /*
                $.getJSON("ajax/test.json", function(data) {
                    var images = [];
                    $.each(data, function(key, val) {
                        images.push(val);
                    });
                });
                */

                var images = [
                    "https://solutudo-cdn.s3-sa-east-1.amazonaws.com/prod/std_cover_photos/27/5943cb2f-d7a4-4d40-9a56-660dac1f146b.jpg",
                    "https://solutudo-cdn.s3-sa-east-1.amazonaws.com/prod/std_cover_photos/125/56faf637-80b8-47e6-b03a-4c8bac1f1305.jpg",
                    "https://solutudo-cdn.s3-sa-east-1.amazonaws.com/prod/std_cover_photos/127/56faf638-c61c-48c6-92c3-4c8bac1f1305.jpg",
                    "https://solutudo-cdn.s3-sa-east-1.amazonaws.com/prod/std_cover_photos/129/56faf638-0d4c-4492-8d45-4c8bac1f1305.jpg",
                    "https://solutudo-cdn.s3-sa-east-1.amazonaws.com/prod/std_cover_photos/130/56faf638-eeb8-48cd-b690-4c8bac1f1305.jpg",
                    "https://solutudo-cdn.s3-sa-east-1.amazonaws.com/prod/std_cover_photos/131/56faf638-66cc-452b-8608-4c8bac1f1305.jpg",
                    "https://solutudo-cdn.s3-sa-east-1.amazonaws.com/prod/std_cover_photos/132/56faf639-b374-4439-9cab-4c8bac1f1305.jpg",
                    "https://solutudo-cdn.s3-sa-east-1.amazonaws.com/prod/std_cover_photos/133/56faf639-c2dc-425d-a4d8-4c8bac1f1305.jpg",
                    "https://solutudo-cdn.s3-sa-east-1.amazonaws.com/prod/std_cover_photos/134/56faf639-19f4-43d1-862e-4c8bac1f1305.jpg",
                    "https://solutudo-cdn.s3-sa-east-1.amazonaws.com/prod/std_cover_photos/135/56faf639-270c-4fe5-b7bb-4c8bac1f1305.jpg",
                    "https://solutudo-cdn.s3-sa-east-1.amazonaws.com/prod/std_cover_photos/137/56faf63a-c830-4138-9dd9-4c8bac1f1305.jpg",
                    "https://solutudo-cdn.s3-sa-east-1.amazonaws.com/prod/std_cover_photos/140/56faf63a-8cc4-4d5d-a12b-4c8bac1f1305.png",
                    "https://solutudo-cdn.s3-sa-east-1.amazonaws.com/prod/std_cover_photos/144/56faf63b-4b18-4600-8165-4c8bac1f1305.jpg",
                    "https://solutudo-cdn.s3-sa-east-1.amazonaws.com/prod/std_cover_photos/145/56faf63b-f3f4-46ae-8e39-4c8bac1f1305.jpg",
                    "https://solutudo-cdn.s3-sa-east-1.amazonaws.com/prod/std_cover_photos/146/56faf63b-8fe4-4f84-b3a4-4c8bac1f1305.jpg",
                    "https://solutudo-cdn.s3-sa-east-1.amazonaws.com/prod/std_cover_photos/148/56faf63c-6a28-4874-bf76-4c8bac1f1305.jpg",
                    "https://solutudo-cdn.s3-sa-east-1.amazonaws.com/prod/std_cover_photos/149/56faf63c-43c8-4a40-84b6-4c8bac1f1305.jpg",
                    "https://solutudo-cdn.s3-sa-east-1.amazonaws.com/prod/std_cover_photos/150/56faf63c-0b14-4d35-bb8f-4c8bac1f1305.jpg",
                    "https://solutudo-cdn.s3-sa-east-1.amazonaws.com/prod/std_cover_photos/151/56faf63c-1a88-4138-b1a7-4c8bac1f1305.jpg",
                    "https://solutudo-cdn.s3-sa-east-1.amazonaws.com/prod/std_cover_photos/152/56faf63c-8514-45ab-8d48-4c8bac1f1305.jpg",
                    "https://solutudo-cdn.s3-sa-east-1.amazonaws.com/prod/std_cover_photos/153/56faf63d-3538-487b-980a-4c8bac1f1305.jpg",
                    "https://solutudo-cdn.s3-sa-east-1.amazonaws.com/prod/std_cover_photos/154/571e8713-0934-4399-81d4-232cac1f1305.jpg",
                    "https://solutudo-cdn.s3-sa-east-1.amazonaws.com/prod/std_cover_photos/155/571e9789-250c-4d84-b395-2ecfac1f1305.jpg",
                    "https://solutudo-cdn.s3-sa-east-1.amazonaws.com/prod/std_cover_photos/156/574dd2b1-7370-4544-a61e-72abac1f1305.jpg",
                    "https://solutudo-cdn.s3-sa-east-1.amazonaws.com/prod/std_cover_photos/157/575706c6-37f0-492f-adad-666fac1f1305.jpg",
                    "https://solutudo-cdn.s3-sa-east-1.amazonaws.com/prod/std_cover_photos/158/57570789-983c-4940-b61e-66a7ac1f1305.jpg",
                    "https://solutudo-cdn.s3-sa-east-1.amazonaws.com/prod/std_cover_photos/159/57570884-df4c-4bb9-afa6-69ceac1f1305.jpg",
                    "https://solutudo-cdn.s3-sa-east-1.amazonaws.com/prod/std_cover_photos/160/5757095c-0dc4-441d-8823-6a9fac1f1305.jpg",
                    "https://solutudo-cdn.s3-sa-east-1.amazonaws.com/prod/std_cover_photos/161/577a4636-cc28-4b1c-b58c-26d6ac1f1305.jpg",
                    "https://solutudo-cdn.s3-sa-east-1.amazonaws.com/prod/std_cover_photos/162/577a46fe-0680-48f1-8baf-27bcac1f1305.jpg",
                    "https://solutudo-cdn.s3-sa-east-1.amazonaws.com/prod/std_cover_photos/163/577a4753-1fe4-4af2-8540-2745ac1f1305.jpg",
                    "https://solutudo-cdn.s3-sa-east-1.amazonaws.com/prod/std_cover_photos/165/57b23352-10d0-4191-bb3b-47a4ac1f1b5c.jpg",
                    "https://solutudo-cdn.s3-sa-east-1.amazonaws.com/prod/std_cover_photos/166/57b23386-6e28-4b0a-9dfd-4869ac1f1b5c.jpg",
                    "https://solutudo-cdn.s3-sa-east-1.amazonaws.com/prod/std_cover_photos/168/57bf177f-c7f8-4eb5-9df3-3460ac1f1b5c.jpg",
                    "https://solutudo-cdn.s3-sa-east-1.amazonaws.com/prod/std_cover_photos/169/57bf1931-5928-410c-b20e-3600ac1f1b5c.jpg",
                    "https://solutudo-cdn.s3-sa-east-1.amazonaws.com/prod/std_cover_photos/170/57ebe186-d568-4b11-924f-2c8fac1f15ae.jpg",
                    "https://solutudo-cdn.s3-sa-east-1.amazonaws.com/prod/std_cover_photos/171/57ebe1bf-0898-4d5b-b7ab-2ce3ac1f15ae.jpg",
                    "https://solutudo-cdn.s3-sa-east-1.amazonaws.com/prod/std_cover_photos/172/57f3efc0-1dbc-4937-b243-5f95ac1f1c05.jpg",
                    "https://solutudo-cdn.s3-sa-east-1.amazonaws.com/prod/std_cover_photos/173/5810a929-7e8c-411a-a99f-4fbaac1f149e.jpg",
                    "https://solutudo-cdn.s3-sa-east-1.amazonaws.com/prod/std_cover_photos/175/580a0d63-67ac-411d-bae5-0e9eac1f1b6a.jpg",
                    "https://solutudo-cdn.s3-sa-east-1.amazonaws.com/prod/std_cover_photos/176/580a0e08-57ec-4f21-b211-0fa8ac1f1b6b.jpg",
                    "https://solutudo-cdn.s3-sa-east-1.amazonaws.com/prod/std_cover_photos/178/5837599d-5768-44f9-99d0-4db3ac1f11d2.jpg",
                    "https://solutudo-cdn.s3-sa-east-1.amazonaws.com/prod/std_cover_photos/179/58375bc3-f3cc-4177-a0f0-4f0fac1f11d2.jpg",
                    "https://solutudo-cdn.s3-sa-east-1.amazonaws.com/prod/std_cover_photos/181/5979fa6a-b6b4-4c1b-8c93-688aac1f1957.jpg",
                    "https://solutudo-cdn.s3-sa-east-1.amazonaws.com/prod/std_cover_photos/182/58375eb8-7c4c-48be-b558-4fc0ac1f11d2.jpg",
                    "https://solutudo-cdn.s3-sa-east-1.amazonaws.com/prod/std_cover_photos/183/58375f6e-3a1c-4ae0-8e51-3ae2ac1f1ee7.jpg",
                    "https://solutudo-cdn.s3-sa-east-1.amazonaws.com/prod/std_cover_photos/185/583761fe-e668-4856-8a83-3b3fac1f1ee7.jpg",
                    "https://solutudo-cdn.s3-sa-east-1.amazonaws.com/prod/std_cover_photos/186/58376304-d5c8-4294-a0d0-5186ac1f11d2.jpg",
                    "https://solutudo-cdn.s3-sa-east-1.amazonaws.com/prod/std_cover_photos/187/5837643c-830c-4fd4-9d4f-3c92ac1f1ee7.jpg",
                    "https://solutudo-cdn.s3-sa-east-1.amazonaws.com/prod/std_cover_photos/188/583764d4-0c7c-43b3-bfc8-3ce1ac1f1ee7.jpg",
                    "https://solutudo-cdn.s3-sa-east-1.amazonaws.com/prod/std_cover_photos/189/58761347-fad4-44b8-a798-1e92ac1f11d8.jpg",
                    "https://solutudo-cdn.s3-sa-east-1.amazonaws.com/prod/std_cover_photos/190/58a19bbf-1b48-40b1-87e7-1437ac1f188b.jpg",
                    "https://solutudo-cdn.s3-sa-east-1.amazonaws.com/prod/std_cover_photos/191/58a19c2d-11a8-457c-847c-454cac1f17f6.jpg",
                    "https://solutudo-cdn.s3-sa-east-1.amazonaws.com/prod/std_cover_photos/192/58a19c82-aed4-4ee8-a095-4805ac1f17f6.jpg",
                    "https://solutudo-cdn.s3-sa-east-1.amazonaws.com/prod/std_cover_photos/193/58b6fd5f-54f8-49c0-854d-383aac1f1a4f.jpg",
                    "https://solutudo-cdn.s3-sa-east-1.amazonaws.com/prod/std_cover_photos/194/58c68645-e1b8-4391-b358-397dac1f1782.jpg",
                    "https://solutudo-cdn.s3-sa-east-1.amazonaws.com/prod/std_cover_photos/195/58c93520-be4c-4482-96f9-4e83ac1f1d7b.jpg",
                    "https://solutudo-cdn.s3-sa-east-1.amazonaws.com/prod/std_cover_photos/196/58d2c672-773c-47e4-98e7-31ddac1f1a53.jpg",
                    "https://solutudo-cdn.s3-sa-east-1.amazonaws.com/prod/std_cover_photos/197/58d2c70f-17ec-4a64-bcca-3148ac1f1a53.jpg",
                    "https://solutudo-cdn.s3-sa-east-1.amazonaws.com/prod/std_cover_photos/198/58d2c767-8a54-4535-a498-1616ac1f173a.jpg",
                    "https://solutudo-cdn.s3-sa-east-1.amazonaws.com/prod/std_cover_photos/199/58d40978-f6e8-4a93-ac0e-0cc0ac1f1f90.jpg",
                    "https://solutudo-cdn.s3-sa-east-1.amazonaws.com/prod/std_cover_photos/200/58d409fb-2284-4f0a-81bc-50cfac1f16ca.jpg",
                    "https://solutudo-cdn.s3-sa-east-1.amazonaws.com/prod/std_cover_photos/201/58eb638d-64a4-4751-b335-3c6cac1f181c.jpg",
                    "https://solutudo-cdn.s3-sa-east-1.amazonaws.com/prod/std_cover_photos/202/58fe0143-e8e0-47de-b1ec-46fbac1f1f85.jpg",
                    "https://solutudo-cdn.s3-sa-east-1.amazonaws.com/prod/std_cover_photos/203/58fe019a-c30c-4e7d-9bde-5ba1ac1f175b.jpg",
                    "https://solutudo-cdn.s3-sa-east-1.amazonaws.com/prod/std_cover_photos/204/58fe01ed-e408-4264-a7be-5bc0ac1f175b.jpg",
                    "https://solutudo-cdn.s3-sa-east-1.amazonaws.com/prod/std_cover_photos/205/5911a18c-59d0-440f-979c-5e04ac1f1177.jpg",
                    "https://solutudo-cdn.s3-sa-east-1.amazonaws.com/prod/std_cover_photos/206/592d5725-b9d8-4a2d-82af-2d78ac1f1e43.jpg",
                    "https://solutudo-cdn.s3-sa-east-1.amazonaws.com/prod/std_cover_photos/207/592d57ee-3590-4f3e-8d6c-2e9aac1f1e43.jpg",
                    "https://solutudo-cdn.s3-sa-east-1.amazonaws.com/prod/std_cover_photos/208/594428b0-2084-486d-a92e-0e4bac1f1541.jpg",
                    "https://solutudo-cdn.s3-sa-east-1.amazonaws.com/prod/std_cover_photos/209/594956fc-647c-4ffb-9377-0cd7ac1f172d.jpg",
                    "https://solutudo-cdn.s3-sa-east-1.amazonaws.com/prod/std_cover_photos/210/59495760-fbf0-41ec-ba1d-0db1ac1f172d.jpg",
                    "https://solutudo-cdn.s3-sa-east-1.amazonaws.com/prod/std_cover_photos/211/59495877-8248-4fb4-9f26-13dbac1f1982.jpg",
                    "https://solutudo-cdn.s3-sa-east-1.amazonaws.com/prod/std_cover_photos/212/594958d0-f4b8-4851-9e20-1537ac1f1982.jpg",
                    "https://solutudo-cdn.s3-sa-east-1.amazonaws.com/prod/std_cover_photos/214/59495997-ad30-4466-82b2-101fac1f172d.jpg",
                    "https://solutudo-cdn.s3-sa-east-1.amazonaws.com/prod/std_cover_photos/215/59495a0e-8be0-4179-a19f-10f3ac1f172d.jpg",
                    "https://solutudo-cdn.s3-sa-east-1.amazonaws.com/prod/std_cover_photos/216/59495a74-5768-4b97-8f66-114cac1f172d.jpg",
                    "https://solutudo-cdn.s3-sa-east-1.amazonaws.com/prod/std_cover_photos/217/59495ae1-9628-4705-bda6-11b9ac1f172d.jpg",
                    "https://solutudo-cdn.s3-sa-east-1.amazonaws.com/prod/std_cover_photos/218/5956913e-1a0c-47ac-b17b-16ddac1f1c87.jpg",
                    "https://solutudo-cdn.s3-sa-east-1.amazonaws.com/prod/std_cover_photos/219/596d02a7-faec-489e-a204-24fdac1f1caa.jpg",
                    "https://solutudo-cdn.s3-sa-east-1.amazonaws.com/prod/std_cover_photos/220/596d0376-7b28-41b6-9506-25a3ac1f1caa.jpg",
                    "https://solutudo-cdn.s3-sa-east-1.amazonaws.com/prod/std_cover_photos/221/596d03e3-0208-48bb-88f9-514cac1f18d1.jpg",
                    "https://solutudo-cdn.s3-sa-east-1.amazonaws.com/prod/std_cover_photos/222/598236fa-0660-4b2d-afed-41e6ac1f14e1.jpg",
                    "https://solutudo-cdn.s3-sa-east-1.amazonaws.com/prod/std_cover_photos/223/5981bfc8-fe44-4968-bb0a-4c4eac1f124d.jpg",
                    "https://solutudo-cdn.s3-sa-east-1.amazonaws.com/prod/std_cover_photos/224/5981ca86-0598-4e5f-98fb-49d4ac1f1206.jpg",
                    "https://solutudo-cdn.s3-sa-east-1.amazonaws.com/prod/std_cover_photos/225/5981cb52-e1d0-4d64-92a7-59e2ac1f124d.jpg",
                    "https://solutudo-cdn.s3-sa-east-1.amazonaws.com/prod/std_cover_photos/226/5981cc67-a410-442e-b4c6-49eaac1f1206.jpg",
                    "https://solutudo-cdn.s3-sa-east-1.amazonaws.com/prod/std_cover_photos/227/5981ccf7-13ec-4348-8d62-4e55ac1f1206.jpg",
                    "https://solutudo-cdn.s3-sa-east-1.amazonaws.com/prod/std_cover_photos/228/5981cd9e-3dac-4826-9c50-4e05ac1f1206.jpg",
                    "https://solutudo-cdn.s3-sa-east-1.amazonaws.com/prod/std_cover_photos/229/5981ce16-f1e0-4f93-9624-5049ac1f1206.jpg",
                    "https://solutudo-cdn.s3-sa-east-1.amazonaws.com/prod/std_cover_photos/230/5981ce79-cee8-4aed-a062-5d91ac1f124d.jpg",
                    "https://solutudo-cdn.s3-sa-east-1.amazonaws.com/prod/std_cover_photos/231/5981cef9-0ffc-4dc9-a224-5d71ac1f124d.jpg",
                    "https://solutudo-cdn.s3-sa-east-1.amazonaws.com/prod/std_cover_photos/232/5981cf5c-e65c-4d65-9ed3-5f65ac1f124d.jpg",
                    "https://solutudo-cdn.s3-sa-east-1.amazonaws.com/prod/std_cover_photos/233/5981cfbe-bf60-4f0c-b9d7-60b8ac1f124d.jpg",
                    "https://solutudo-cdn.s3-sa-east-1.amazonaws.com/prod/std_cover_photos/234/5981d033-d9e8-49f5-8f00-548aac1f1206.jpg",
                    "https://solutudo-cdn.s3-sa-east-1.amazonaws.com/prod/std_cover_photos/235/5981d0ae-a950-42a0-b738-5520ac1f1206.jpg",
                    "https://solutudo-cdn.s3-sa-east-1.amazonaws.com/prod/std_cover_photos/236/5981d1ad-88e0-4f69-ac84-5688ac1f1206.jpg",
                    "https://solutudo-cdn.s3-sa-east-1.amazonaws.com/prod/std_cover_photos/237/5981d220-e4bc-4f8d-b805-64d5ac1f124d.jpg",
                    "https://solutudo-cdn.s3-sa-east-1.amazonaws.com/prod/std_cover_photos/238/5981d2d8-3f90-4fbd-908a-5878ac1f1206.jpg",
                    "https://solutudo-cdn.s3-sa-east-1.amazonaws.com/prod/std_cover_photos/239/5981d354-3984-47fa-be14-653fac1f124d.jpg",
                    "https://solutudo-cdn.s3-sa-east-1.amazonaws.com/prod/std_cover_photos/241/5981d4c2-368c-469f-aadc-5a42ac1f1206.jpg",
                    "https://solutudo-cdn.s3-sa-east-1.amazonaws.com/prod/std_cover_photos/242/5981d57c-ace4-407d-8717-5b9bac1f1206.jpg",
                    "https://solutudo-cdn.s3-sa-east-1.amazonaws.com/prod/std_cover_photos/243/5981e885-8314-4699-9460-1784ac1f1145.jpg",
                    "https://solutudo-cdn.s3-sa-east-1.amazonaws.com/prod/std_cover_photos/244/59823600-d410-45c0-8edb-41a4ac1f14e1.jpg",
                    "https://solutudo-cdn.s3-sa-east-1.amazonaws.com/prod/std_cover_photos/245/59931b8f-a638-41cd-b060-1ae5ac1f15d3.jpg",
                    "https://solutudo-cdn.s3-sa-east-1.amazonaws.com/prod/std_cover_photos/246/59931c21-cd90-4825-8ce7-1c33ac1f19be.jpg",
                    "https://solutudo-cdn.s3-sa-east-1.amazonaws.com/prod/std_cover_photos/247/59931c7f-29b8-4038-89e1-1b39ac1f15d3.jpg",
                    "https://solutudo-cdn.s3-sa-east-1.amazonaws.com/prod/std_cover_photos/248/59947956-516c-4c95-be00-2390ac1f1f54.jpg",
                    "https://solutudo-cdn.s3-sa-east-1.amazonaws.com/prod/std_cover_photos/249/599479df-ec3c-4da3-b6ef-4a84ac1f1ed9.jpg",
                ];
                if (slider_config.shuffle == true) {
                    images = images.slice(0, slider_config.limit);
                    Login.shuffle(images);
                }
            } else {
                images = [
                    "https://solutudo-cdn.s3-sa-east-1.amazonaws.com/prod/std_cover_photos/140/56faf63a-8cc4-4d5d-a12b-4c8bac1f1305.png"
                ]
            }
            $('.login-bg').backstretch(images, {
                    fade: slider_config.fade,
                    duration: slider_config.duration
                }
            );
            $('#forget-password').click(function(){
                $('.login-form').hide();
                $('.forget-form').show();
            });
            $('#back-btn').click(function(){
                $('.login-form').show();
                $('.forget-form').hide();
            });
            $('.forget-form').hide();
        },

        shuffle: function(array) {
            var currentIndex = array.length, temporaryValue, randomIndex;
            while (0 !== currentIndex) {
                randomIndex = Math.floor(Math.random() * currentIndex);
                currentIndex -= 1;
                temporaryValue = array[currentIndex];
                array[currentIndex] = array[randomIndex];
                array[randomIndex] = temporaryValue;
            }
            return array;
        },

        checkPasswordMatch: function() {
            var passwordSubmit = $('#newPassSubmit');
            passwordSubmit.attr('disabled', true);
            $('#newPassConfirm').keyup(function() {
                var password = $('#newPass').val();
                var passwordMsg = $('#checkPasswordMatch');
                passwordMsg.addClass('hidden');
                if (password == $(this).val()) {
                    passwordMsg.addClass('hidden');
                    passwordSubmit.removeAttr('disabled');
                } else {
                    passwordMsg.removeClass('hidden');
                    passwordSubmit.attr('disabled', true);
                }
            });
        }

    };
}();
jQuery(document).ready(function() {
    Login.init();
});
